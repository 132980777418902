import { ACCOUNT_REJECTED_MESSAGE } from '@constants/messages';

import { NotificationType } from '@models/application/notifications';

import { addNotification } from '@utils/notifications';

export const onAccountRejected = () => {
  addNotification({
    type: NotificationType.AccountRejected,
    payload: {
      config: { closable: false },
      content: { text: ACCOUNT_REJECTED_MESSAGE },
    },
  });
};
