import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { modifyProfileSettingAction } from '@store/settings';
import { setActivePortfolio } from '~/entities/protfolio';

export const useSetActivePortfolio = () => {
  const dispatch = useDispatch();

  return useCallback(
    (portfolioId: string) => {
      dispatch(setActivePortfolio(portfolioId));
      dispatch(modifyProfileSettingAction({ lastActivePortfolioId: portfolioId }));
    },
    [dispatch],
  );
};
