import React from 'react';
import * as S from './Survey.styled';

const scores = Array.from({ length: 10 }).map((_, i) => i + 1);

type Props = {
  onClick: (score: number) => void;
  organizationName?: string;
};

export const Survey: React.FC<Props> = (props) => {
  return (
    <S.Container>
      <S.GlobalStyles />
      <S.Title>How do you like {props.organizationName} digital investing?</S.Title>
      <S.GradeContainer>
        {scores.map((score) => (
          <S.GradeButton key={score} onClick={() => props.onClick(score)}>
            <S.GradeNumber>{score}</S.GradeNumber>
          </S.GradeButton>
        ))}
      </S.GradeContainer>
      <S.Info>
        <S.InfoText>1 - Not at all</S.InfoText>
        <S.InfoText>Extremely - 10</S.InfoText>
      </S.Info>
    </S.Container>
  );
};
