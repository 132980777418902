import { useContext, useEffect } from 'react';

import { dispatchCompanyDefaults } from '@utils/application';
import { AppContext } from '~/contexts/app';
import { useOrganizationSettings } from '~/entities/Organization';

import { ErrorPageTemplate } from '~/ui/layouts/ErrorPageTemplate';

import * as S from './Maintenance.styled';

export const MaintenancePage = () => {
  const appDispatch = useContext(AppContext);

  const companySettings = useOrganizationSettings();

  useEffect(
    () => {
      dispatchCompanyDefaults(appDispatch, companySettings);
    },
    // eslint-disable-next-line
    [companySettings]);

  return (
    <ErrorPageTemplate
      loadStyles
      disableAction
      title="Maintenance in progress"
      subtitle="We are preparing new amazing features for you!"
      actionLabel="Stay tuned!"
      icon={<S.Icon />}
    />
  );
};
