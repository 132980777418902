import styled, { createGlobalStyle } from 'styled-components';

import { gaps, getBorderRadius } from '~/ui/kit/constants/sizes';
import { fontSize } from '~/ui/kit/constants/fonts';
import { media } from '~/ui/utils/media';

import { Button } from '~/ui/kit/atoms/Button';
import { IconCrossRounded } from '~/ui/kit/atoms/IconCrossRounded';

export const GlobalStyles = createGlobalStyle`
    .survey-notification {
        & .ant-notification-notice-message {
            margin-inline-start: ${gaps.s}px !important;
        }

        &.ant-notification-notice {
            width: 100%;
            padding: ${gaps.s}px;
        }

        & > .ant-notification-notice-close {
            display: none;
        }
    }
`;
export const Container = styled.div``;

export const CloseButton = styled(Button).attrs({
  type: 'ghost',
})`
  position: absolute;
  top: 0;
  right: 0;
`;
export const CloseIcon = styled(IconCrossRounded)`
  width: 20px;
  height: 20px;
  color: var(--eko-third-color-light);
`;
export const Title = styled.p.attrs({ fs: 's' })`
  margin: 0 0 ${gaps.xl}px;
  ${fontSize};
  font-weight: bold;
  padding-right: ${gaps.xxs}px;
  color: var(--eko-third-color);
`;

export const GradeContainer = styled.div`
  display: flex;
  gap: ${gaps.xxs / 2}px;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  margin-top: ${gaps.xl}px;
  margin-bottom: ${gaps.s}px;

  ${media.xs`
    gap: ${gaps.xxs}px;
  `}
`;

export const GradeButton = styled(Button).attrs({ fs: 'm', br: 'm' })`
  width: 42px;
  display: flex;
  justify-content: center;
  color: var(--eko-third-color-light);
  ${getBorderRadius};

  &.ant-btn {
    border: 1px solid var(--eko-third-color-light);
  }

  &:hover {
    background: var(--eko-primary-color-light-2);

    &.ant-btn {
      border-color: var(--eko-primary-color-light);
    }
  }
`;

export const GradeNumber = styled.span`
  color: var(--eko-primary-color);
  font-weight: bold;
`;

export const Info = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: ${gaps.s}px;
`;

export const InfoText = styled.span.attrs({ $fs: 'xs' })`
  color: var(--eko-third-color-light);
  ${fontSize};
`;
