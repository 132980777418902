import * as components from '../components';
import * as routes from '../names';
import { ProtectedRoute } from '../ProtectedRoute';
import { RouteLayout } from '../RouteLayout';
import { getPathAndKeyPropsFactory } from './helpers';

const getPathAndKeyProps = getPathAndKeyPropsFactory('no-account');

export const getNoAccountRoutes = (isExternalUserOnboarding: boolean) => [
  <ProtectedRoute
    isAllowed={isExternalUserOnboarding}
    redirectPath={routes.ERROR_PAGE}
    layout="fullscreen"
    {...getPathAndKeyProps(routes.GET_STARTED_EXTERNAL)}
    component={components.OnboardingExternal}
  />,
  <RouteLayout layout="fullscreen" {...getPathAndKeyProps(routes.ERROR_PAGE)} component={components.ErrorPage} />,
  <ProtectedRoute
    isAllowed={false}
    redirectPath={routes.GET_STARTED_EXTERNAL}
    layout="fullscreen"
    {...getPathAndKeyProps(routes.ANY)}
    component={components.PageNotFound}
  />,
];
