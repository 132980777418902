import React, { CSSProperties, forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import classes from 'classnames';

import s from './style.module.css';

import { getIsAccountClosure } from '@store/profile/selectors';
import MobileMenu from '@components/layout/mobile-menu/MobileMenu';
import { BurgerButton } from './ui/burgerButton';
import HorizontalMenu from './ui/horizontal-menu';

import * as S from './Header.widget.styled';
import { checkDeviceSize, useAppMediaContext } from '~/contexts/AppMedia';

interface Props {
  className?: string;
  selectorClassName?: string;
  style?: CSSProperties;
}

export const Header = forwardRef(({ className, style, selectorClassName }: Props, ref?: React.Ref<HTMLHeadElement>) => {
  const { deviceSize } = useAppMediaContext();
  const isMobile = !checkDeviceSize.moreOrEqual.lg(deviceSize);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const closure = getIsAccountClosure();

  const [isMenuOpened, setMenuOpened] = useState(false);
  const [headerContainerLeftPosition, setHeaderContainerLeftPosition] = useState(0);

  const calculateHeaderLeftPosition = useCallback(() => {
    setHeaderContainerLeftPosition(containerRef.current?.offsetLeft || 0);
  }, [containerRef]);

  const closeMobileMenu = useCallback(() => {
    void setMenuOpened(false);
  }, []);

  /* Close mobile menu in case orientation changed to mobile+ */
  useEffect(() => {
    if (!isMobile) {
      void closeMobileMenu();
    }
  }, [isMobile, setMenuOpened, closeMobileMenu]);

  const content = useMemo(() => {
    if (isMobile) {
      return (
        <>
          <BurgerButton
            className={classes(s.header__content__burger, isMenuOpened && s.header__content__burger_opened)}
            opened={isMenuOpened}
            onClick={() => setMenuOpened(!isMenuOpened)}
          />

          <MobileMenu className={s.header__content__mobile} visible={isMenuOpened} onClose={closeMobileMenu} />
        </>
      );
    }

    return <HorizontalMenu />;
  }, [isMobile, isMenuOpened, closeMobileMenu]);

  return (
    <S.Header style={style} className={classes(s.header, className)} ref={ref}>
      <S.ControlsContainer ref={containerRef} className={classes(s.header__content, 'container')}>
        {!closure && (
          <S.PortfolioSelector
            onVisibilityChanged={calculateHeaderLeftPosition}
            className={selectorClassName}
            leftPosition={headerContainerLeftPosition}
          />
        )}
        {content}
      </S.ControlsContainer>
    </S.Header>
  );
});
