import React, { useCallback, useEffect, useMemo } from 'react';
import * as RD from '@devexperts/remote-data-ts';
import { pipe } from 'fp-ts/function';
import { useSelector } from 'react-redux';
import * as A from 'fp-ts/Array';

import { fromOption } from '@utils/RemoteData';
import { selectOrganization } from '@store/organization/selectors';

import { useNotification } from '~/shared/hooks/useNotification';
import { NotificationType } from '~/contexts/notifications';
import { PopupsNames, usePopups } from '~/entities/popups';
import { useSurvey } from '~/entities/survey';

import { Survey } from '../../ui';
import * as S from '../../ui/Survey.styled';

const SURVEY_NOTIFICATION_KEY = 'SURVEY_KEY';

export const useSurveyNotification = (enabled: boolean) => {
  const [_, setScore] = useSurvey();
  const [popupsData, getPopupsData] = usePopups();
  const organization = useSelector(selectOrganization);

  const { openNotification, closeNotification } = useNotification();

  useEffect(() => {
    if (enabled) {
      void getPopupsData();
    }
  }, [getPopupsData, enabled]);

  const handleClick = useCallback(
    (score: number) => {
      setScore(score);
      closeNotification(SURVEY_NOTIFICATION_KEY);
    },
    [closeNotification, setScore],
  );

  const handleClose = useCallback(() => {
    setScore(null);
    closeNotification(SURVEY_NOTIFICATION_KEY);
  }, [closeNotification, setScore]);

  const showNotification = useCallback(() => {
    openNotification({
      message: <Survey onClick={handleClick} organizationName={organization?.display_name} />,
      placement: 'bottomRight',
      type: NotificationType.INFO,
      duration: 0,
      key: SURVEY_NOTIFICATION_KEY,
      btn: (
        <S.CloseButton onClick={handleClose}>
          <S.CloseIcon />
        </S.CloseButton>
      ),
      className: 'survey-notification',
    });
  }, [openNotification, handleClose, handleClick, organization]);

  const isShow = useMemo(
    () =>
      pipe(
        popupsData,
        RD.map(A.findFirst((item) => item.name === PopupsNames.SURVEY)),
        RD.chain(fromOption(() => RD.failure({ code: 404, message: 'No popup found' }))),
        RD.map(Boolean),
        RD.getOrElse(() => false),
      ),
    [popupsData],
  );

  useEffect(() => {
    if (isShow) {
      showNotification();
    }
  }, [showNotification, isShow]);
};
