import { useEffect } from 'react';

import { Profile } from '@models/profile';
import { onAccountClosure } from '@domain/events/closure';
import { onAccountRejected } from '@domain/events/rejected';
import { getIsAccountClosure } from '@store/profile/selectors';

export const useMainNotifications = (profileInfo: Profile | null) => {
  const isAccountClosed = getIsAccountClosure();
  const isAccountRejected = Boolean(profileInfo?.rejected);

  useEffect(() => {
    if (isAccountRejected) {
      return onAccountRejected();
    }

    if (isAccountClosed) {
      return onAccountClosure();
    }
  }, [isAccountClosed, isAccountRejected]);
};
