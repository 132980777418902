import { Organization } from '@models/organization';
import { ACH_SELECTION_TYPES } from '~/constants/accounts';

const EKO_LOGO = 'https://eko-app.s3.us-east-1.amazonaws.com/eko/organizations/logos/eko_logo.svg';

const THEME_TYPE_KEY = 'theme';
const SETTINGS_TYPE_KEY = 'settings';
const STYLES_TYPE_KEY = 'styles';
const INFO_TYPE_KEY = 'info';

export const DEPOSIT_MINIMUM_KEY = 'deposit_minimum';
export const WITHDRAWAL_MINIMUM_KEY = 'withdrawal_minimum';
export const ACH_SELECTION_TYPE_KEY = 'ach_selection_type';
export const IS_PASSWORD_CHANGE_AVAILABLE_KEY = 'password_change_available';

export const DISPLAY_NAME_KEY = 'displayName';

export const LOGO_KEY = 'logo';
export const EMOJI_KEY = 'emoji';

const DEFAULT_SETTINGS = {
  [INFO_TYPE_KEY]: {
    [DISPLAY_NAME_KEY]: 'Eko',
  },
  [THEME_TYPE_KEY]: {
    [LOGO_KEY]: EKO_LOGO,
    [EMOJI_KEY]: true,
  },
  [SETTINGS_TYPE_KEY]: {
    [DEPOSIT_MINIMUM_KEY]: 10,
    [WITHDRAWAL_MINIMUM_KEY]: 2,
    [ACH_SELECTION_TYPE_KEY]: ACH_SELECTION_TYPES.MANUAL,
    [IS_PASSWORD_CHANGE_AVAILABLE_KEY]: true,
  },
  [STYLES_TYPE_KEY]: '',
};

export type SettingsContextState = typeof DEFAULT_SETTINGS;

type FirstLevelKeys = keyof SettingsContextState;
type FirstLevelKeysWithoutStyles = Exclude<FirstLevelKeys, 'styles'>;

const getPositiveNumber = (value: unknown, defaultValue: number) => {
  const number = Number(value);

  if (Number.isNaN(number)) {
    return defaultValue;
  }

  return Number.isFinite(number) && number >= 0 ? number : defaultValue;
};

const getBooleanValue = (value: unknown, defaultValue: boolean) => (typeof value === 'boolean' ? value : defaultValue);

const parseCompanySettings = (company: Organization) => {
  const defaultTheme = DEFAULT_SETTINGS[THEME_TYPE_KEY];
  const defaultSettings = DEFAULT_SETTINGS[SETTINGS_TYPE_KEY];
  const defaultStyles = DEFAULT_SETTINGS[STYLES_TYPE_KEY];
  const defaultInfo = DEFAULT_SETTINGS[INFO_TYPE_KEY];

  return {
    [THEME_TYPE_KEY]: {
      [LOGO_KEY]: company.logo || defaultTheme[LOGO_KEY],
      [EMOJI_KEY]: company.is_emoji_enabled,
    },
    [SETTINGS_TYPE_KEY]: {
      [DEPOSIT_MINIMUM_KEY]: getPositiveNumber(company.deposit_minimum, defaultSettings[DEPOSIT_MINIMUM_KEY]),
      [WITHDRAWAL_MINIMUM_KEY]: getPositiveNumber(company.withdrawal_minimum, defaultSettings[WITHDRAWAL_MINIMUM_KEY]),
      [ACH_SELECTION_TYPE_KEY]: getPositiveNumber(company.ach_selection, defaultSettings[ACH_SELECTION_TYPE_KEY]),
      [IS_PASSWORD_CHANGE_AVAILABLE_KEY]: getBooleanValue(
        company.is_password_change_enabled,
        defaultSettings[IS_PASSWORD_CHANGE_AVAILABLE_KEY],
      ),
    },
    [STYLES_TYPE_KEY]: company.css || defaultStyles,
    [INFO_TYPE_KEY]: {
      [DISPLAY_NAME_KEY]: company.display_name || defaultInfo[DISPLAY_NAME_KEY],
    },
  };
};

export const getCompanySettings = (company: Organization | null) =>
  company ? parseCompanySettings(company) : DEFAULT_SETTINGS;

function getSetting<K extends FirstLevelKeys>(settings: SettingsContextState, firsKey: K): SettingsContextState[K];
function getSetting<K extends FirstLevelKeysWithoutStyles, S extends keyof SettingsContextState[K]>(
  settings: SettingsContextState,
  firstKey: K,
  secondKey: S,
): SettingsContextState[K][S];
function getSetting<K extends FirstLevelKeys, S extends keyof SettingsContextState[K]>(
  settings: SettingsContextState,
  firsKey: K,
  secondKey?: S,
) {
  const current = settings[firsKey];

  if (firsKey === STYLES_TYPE_KEY) {
    return current;
  }

  if (typeof current === 'object' && firsKey !== STYLES_TYPE_KEY && secondKey) {
    return current[secondKey];
  }

  return null;
}

function getSettingOrDefault<K extends FirstLevelKeys>(
  settings: SettingsContextState,
  firstKey: K,
): SettingsContextState[K];
function getSettingOrDefault<K extends FirstLevelKeysWithoutStyles, S extends keyof SettingsContextState[K]>(
  settings: SettingsContextState,
  firstKey: K,
  secondKey: S,
): SettingsContextState[K][S];
function getSettingOrDefault<K extends FirstLevelKeys, S extends keyof SettingsContextState[K]>(
  settings: SettingsContextState,
  firstKey: K,
  secondKey?: S,
) {
  if (firstKey === STYLES_TYPE_KEY) {
    const setting = getSetting(settings, firstKey);

    if (setting === null) {
      return getSetting(DEFAULT_SETTINGS, firstKey);
    }
    return setting;
  }

  if (!secondKey) {
    const setting = getSetting(settings, firstKey);

    if (setting === null) {
      return getSetting(DEFAULT_SETTINGS, firstKey);
    }
    return setting;
  }

  // eslint-disable-next-line
  // @ts-ignore
  const setting = getSetting(settings, firstKey, secondKey);

  if (setting === null) {
    // eslint-disable-next-line
    // @ts-ignore
    return getSetting(DEFAULT_SETTINGS, firstKey, secondKey);
  }

  return setting;
}

export const getSettingsKey = <K extends keyof SettingsContextState['settings']>(
  settings: SettingsContextState,
  key: K,
) => getSettingOrDefault(settings, SETTINGS_TYPE_KEY, key);

export const getTitle = (settings: SettingsContextState) =>
  getSettingOrDefault(settings, INFO_TYPE_KEY, DISPLAY_NAME_KEY);
export const getPasswordChangeAvailability = (settings: SettingsContextState) =>
  getSettingOrDefault(settings, SETTINGS_TYPE_KEY, IS_PASSWORD_CHANGE_AVAILABLE_KEY);
export const getACHSelectionType = (settings: SettingsContextState) =>
  getSettingOrDefault(settings, SETTINGS_TYPE_KEY, ACH_SELECTION_TYPE_KEY);
export const getStyles = (settings: SettingsContextState) => getSettingOrDefault(settings, STYLES_TYPE_KEY);
export const getLogo = (settings: SettingsContextState) => getSettingOrDefault(settings, THEME_TYPE_KEY, LOGO_KEY);
export const getIsEmojiOn = (settings: SettingsContextState) =>
  getSettingOrDefault(settings, THEME_TYPE_KEY, EMOJI_KEY);
