import * as components from '../components';
import * as routes from '../names';
import { ProtectedRoute } from '../ProtectedRoute';
import { RouteLayout } from '../RouteLayout';
import { getPathAndKeyPropsFactory } from './helpers';

const getPathAndKeyProps = getPathAndKeyPropsFactory('account');

const OnboardingComponent = () => <components.OnboardingAlpaca prefetchAccountData />;

export const getAccountRoutes = (hasAlpacaAccount: boolean) => {
  return [
    <RouteLayout {...getPathAndKeyProps(routes.MAIN)} component={components.PerformancePage} />,
    <RouteLayout {...getPathAndKeyProps(routes.PORTFOLIO)} component={components.MainPage} />,
    <ProtectedRoute
      {...getPathAndKeyProps(routes.FUND_WITHDRAW)}
      isAllowed={hasAlpacaAccount}
      redirectPath={routes.ONBOARDING_ALPACA}
      component={components.FundWithdraw}
    />,
    <RouteLayout
      {...getPathAndKeyProps(routes.NEW_ACCOUNT)}
      component={() => <components.Account hasAlpacaAccount={hasAlpacaAccount} />}
    />,
    <RouteLayout {...getPathAndKeyProps(routes.FAQ)} component={components.FAQ} />,
    <RouteLayout
      {...getPathAndKeyProps(routes.CREATE_PORTFOLIO)}
      layout="fullscreen"
      component={components.NewPortfolio}
    />,
    <RouteLayout {...getPathAndKeyProps(routes.KYC)} layout="fullscreen" component={components.KYC} />,
    <RouteLayout
      {...getPathAndKeyProps(routes.ONBOARDING_ALPACA)}
      layout="fullscreen"
      component={OnboardingComponent}
    />,

    <ProtectedRoute
      isAllowed={false}
      redirectPath={routes.MAIN}
      layout="fullscreen"
      {...getPathAndKeyProps(routes.GET_STARTED_EXTERNAL)}
      component={components.OnboardingExternal}
    />,

    <RouteLayout {...getPathAndKeyProps(routes.ERROR_PAGE)} layout="fullscreen" component={components.ErrorPage} />,
    <RouteLayout {...getPathAndKeyProps(routes.ANY)} layout="fullscreen" component={components.PageNotFound} />,
  ];
};
