import { ACCOUNT_CLOSURE_MESSAGE } from '@constants/messages';

import { NotificationType } from '@models/application/notifications';

import { addNotification } from '@utils/notifications';

export const onAccountClosure = () => {
  addNotification({
    type: NotificationType.AccountClosure,
    payload: {
      config: { closable: false },
      content: { text: ACCOUNT_CLOSURE_MESSAGE },
    },
  });
};
