import loadable from '@loadable/component';

export { AccountPage as Account } from '~/ui/pages/Account';
export { FAQPage as FAQ } from '~/ui/pages/FAQ';
export { FundWithdrawPage as FundWithdraw } from '~/ui/pages/fund_withdraw';
export { PortfolioPage as MainPage } from '~/ui/pages/portfolio';
export { PerformancePage } from '~/ui/pages/performance';
export { NewPortfolioPage as NewPortfolio } from '~/ui/pages/NewPortfolio';
export { KYCPage as KYC } from '~/ui/pages/onboarding_alpaca/KYCPage';
export { ErrorPage } from '~/ui/pages/ErrorPage';
export { OnboardingAlpaca } from '~/ui/pages/onboarding_alpaca/new2/Onboarding.wrapper';
export { OnboardingExternal } from '~/ui/pages/onboarding_external';

export const PageNotFound = loadable(() => import('~/ui/pages/PageNotFound/PageNotFound'));

export const AlpacaOnboarding = loadable(() => import('@pages/AlpacaOnboarding/AlpacaOnboarding'));
