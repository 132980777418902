import { FC, PropsWithChildren, memo, useMemo, useCallback } from 'react';
import { Redirect } from 'react-router-dom';

import { RouteLayout, RouteLayoutProps } from '../RouteLayout';

export type ProtectedRouteProps = PropsWithChildren<
  RouteLayoutProps & {
    redirectPath?: string;
    isAllowed?: boolean;
  }
>;

export const ProtectedRoute: FC<ProtectedRouteProps> = memo(({ component: ComponentProp, isAllowed, ...props }) => {
  const redirectTo = useMemo(() => ({ pathname: props.redirectPath }), [props.redirectPath]);

  const renderProp: ProtectedRouteProps['component'] = useCallback(
    (componentProps: any) => {
      if (isAllowed && ComponentProp) {
        return <ComponentProp {...componentProps} />;
      }

      return <Redirect to={redirectTo} />;
    },
    [redirectTo, ComponentProp, isAllowed],
  );

  return <RouteLayout {...props} component={renderProp} />;
});

ProtectedRoute.defaultProps = {
  layout: 'sidebar',
  redirectPath: '/',
  exact: true,
  isAllowed: true,
};
