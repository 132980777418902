import React, { useCallback, useState, PropsWithChildren } from 'react';
import { useDropzone } from 'react-dropzone';
import { default as PhoneInputComponent, PhoneInputProps as BasePhoneInputProps } from 'react-phone-input-2';

import forwardWhiteArrow from '~/assets/images/forward-white-arrow.png';
import backWhiteArrow from '~/assets/images/back-white-arrow.png';
import enterIcon from '~/assets/images/enterIcon.png';
import uploadIcon from '~/assets/images/uploadIcon.png';
import CrossPopoverIcon from '~/assets/images/crossIconPopover.png';

import styles from './OnboardingAlpaca.module.css';
import { slideNames } from '../../../utils/alpacaUtils';
import Loader from '../../components/Loader/Loader';
import classes from 'classnames';

type CommonProps = PropsWithChildren<{
  id: slideNames;
  isNextPreviousButtonDisabled: boolean;
  isFirstItem?: boolean;
  onClickPreviousBtn: VoidFunction;
  onClickNextBtn: VoidFunction;
  isNavigatedFromConfirmation?: boolean;
  title: React.ReactNode;
  isLastItem?: boolean;
  isLoading?: boolean;
}>;

type SlideContainerProps = CommonProps;

export function SlideContainer({
  isNextPreviousButtonDisabled,
  id,
  isFirstItem,
  children,
  onClickPreviousBtn,
  onClickNextBtn,
  title,
  isNavigatedFromConfirmation,
}: SlideContainerProps) {
  const getOpacity = useCallback(() => {
    if (isFirstItem || isNavigatedFromConfirmation) {
      return 0;
    } else if (isNextPreviousButtonDisabled) {
      return 0.5;
    } else {
      return 1;
    }
  }, [isFirstItem, isNextPreviousButtonDisabled, isNavigatedFromConfirmation]);

  return (
    <div id={id} className={styles.slideContainer}>
      <div>
        <h2 className={styles.formFieldLabel}>{title}</h2>
        <div className={styles.inputBox}>
          <div className={styles.inputContainer}>{children}</div>
        </div>
        <div className={styles.btnsBar}>
          <button
            tabIndex={-1}
            disabled={isFirstItem || isNextPreviousButtonDisabled || isNavigatedFromConfirmation}
            style={{ opacity: getOpacity() }}
            onClick={onClickPreviousBtn}
          >
            <img alt="back" className={styles.arrowIcon} src={backWhiteArrow} />
            <div>Previous</div>
          </button>
          <button
            tabIndex={-1}
            style={{ opacity: isNextPreviousButtonDisabled ? 0.5 : 1 }}
            disabled={isNextPreviousButtonDisabled}
            onClick={onClickNextBtn}
          >
            <div>{isNavigatedFromConfirmation ? 'Save and go back' : 'Next'}</div>
            <img className={styles.arrowIcon} src={forwardWhiteArrow} alt="" />
          </button>
        </div>
        <div className={styles.enterBtn}>
          <span>Press Enter</span>
          <img src={enterIcon} alt="" />
        </div>
      </div>
    </div>
  );
}

type SlideContainerKYCProps = CommonProps;

export function SlideContainerKYC({
  isNextPreviousButtonDisabled,
  id,
  isFirstItem,
  children,
  onClickPreviousBtn,
  onClickNextBtn,
  title,
  isNavigatedFromConfirmation,
  isLastItem,
  isLoading,
}: SlideContainerKYCProps) {
  const getOpacity = () => {
    if (isFirstItem || isNavigatedFromConfirmation) {
      return 0;
    } else if (isNextPreviousButtonDisabled) {
      return 0.5;
    } else {
      return 1;
    }
  };

  return (
    <div id={id} className={styles.slideContainerKYC}>
      <div>
        <h2 className={styles.formFieldLabel}>{title}</h2>
        <div className={styles.inputBoxKYC}>
          <div className={styles.inputContainer}>{children}</div>
        </div>
        <div className={styles.btnsBar}>
          <button
            tabIndex={-1}
            disabled={isFirstItem || isNextPreviousButtonDisabled || isNavigatedFromConfirmation}
            style={{ opacity: getOpacity() }}
            onClick={onClickPreviousBtn}
          >
            <img alt="back" className={styles.arrowIcon} src={backWhiteArrow} />
            <div>Previous</div>
          </button>
          <button
            tabIndex={-1}
            style={{ opacity: isNextPreviousButtonDisabled ? 0.5 : 1 }}
            disabled={isNextPreviousButtonDisabled || isLoading}
            onClick={onClickNextBtn}
          >
            {isLoading ? (
              <Loader customStyle={{ width: '20px', height: '20px' }} />
            ) : (
              <>
                <div>{isLastItem ? 'Done' : 'Next'}</div>
                <img className={styles.arrowIcon} src={forwardWhiteArrow} alt="" />
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

export function UploadInput({
  label,
  onChange,
}: {
  label: React.ReactNode;
  onChange: (params: null | { base64: string; type: string }) => void;
}) {
  const maximumSize = 20;
  const [error, setError] = useState<string | null>(null);
  const [fileInfoValue, setFileInfoValue] = useState<File | null>(null);

  const getBase64 = useCallback((file: File) => {
    return new Promise<string>((resolve) => {
      let baseURL = '';
      const reader = new FileReader();
      // Convert the file to base64 text
      reader.readAsDataURL(file);

      reader.onload = () => {
        baseURL = reader.result?.toString() || '';
        resolve(baseURL);
      };
    });
  }, []);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles[0].size / 1000000 > maximumSize) {
        setError('File size should be less then 20MB');
      } else {
        setError(null);
        setFileInfoValue(acceptedFiles[0]);
        getBase64(acceptedFiles[0]).then((res) => {
          onChange({ base64: res.split(',')[1], type: acceptedFiles[0].type });
        });
      }
    },
    [onChange, getBase64],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const onClickCross: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.stopPropagation();
      setFileInfoValue(null);
      onChange(null);
    },
    [onChange],
  );

  return (
    <React.Fragment>
      <React.Fragment>
        <div className={styles.attachLabel}>{label}</div>
        <div
          {...getRootProps()}
          className={styles.fileUploadComp}
          style={{ borderColor: isDragActive ? 'var(--eko-primary-color)' : '' }}
          tabIndex={-1}
        >
          <input type="text" className={styles.fileInput} tabIndex={-1} />
          <input {...getInputProps()} className={styles.fileInput} accept=".png, .jpeg, .pdf" tabIndex={-1} />
          {fileInfoValue ? (
            <div className="d-flex align-items-center">
              <span style={{ width: '200px', wordBreak: 'break-word' }}>{fileInfoValue.name} </span>
              <button tabIndex={-1} onClick={onClickCross} className={styles.crossBtn}>
                <img src={CrossPopoverIcon} alt="" />
              </button>
            </div>
          ) : (
            <React.Fragment>
              <img src={uploadIcon} alt="" />
              <label className="button" htmlFor="fileElem">
                {isDragActive ? (
                  'Drop files here...'
                ) : (
                  <>
                    <span
                      style={{
                        color: 'var(--eko-primary-color)',
                        textDecoration: 'underline',
                      }}
                    >
                      Browse
                    </span>{' '}
                    your documents
                  </>
                )}
              </label>
            </React.Fragment>
          )}
        </div>
        <div className={styles.uploadRequirements}>Accepted file type: png, pdf, jpeg. Size 20 MB max</div>
        {error && <div className={styles.inputErrorMsg}>{error}</div>}
      </React.Fragment>
    </React.Fragment>
  );
}

type BaseInputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
type TextInputProps = BaseInputProps & {
  refvalue: BaseInputProps['ref'];
  isInvalid?: boolean;
};

export function TextInput({
  className,
  defaultValue,
  onKeyUp,
  value,
  refvalue,
  type = 'text',
  placeholder,
  onChange,
  isInvalid,
  style,
  name,
  title,
  min,
  max,
  onKeyDown,
  onFocus,
  maxLength,
  inputMode,
}: TextInputProps) {
  const modifyEventWithTrimmedValue: React.FocusEventHandler<HTMLInputElement> = useCallback((e) => {
    const newValue = ((e.target && e.target.value) || '').trim();

    if (!e.currentTarget) {
      // eslint-disable-next-line
      // @ts-ignore
      e.currentTarget = {};
    }

    if (!e.target) {
      // eslint-disable-next-line
      // @ts-ignore
      e.target = {};
    }

    e.currentTarget.value = newValue;
    e.target.value = newValue;
  }, []);

  const onBlur: React.FocusEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      modifyEventWithTrimmedValue(e);

      if (onChange) {
        onChange(e);
      }
    },
    [onChange, modifyEventWithTrimmedValue],
  );

  return (
    <div className={className}>
      <div className={styles.textInputLabel}>{title}</div>
      <input
        onKeyUp={onKeyUp}
        ref={refvalue}
        placeholder={placeholder}
        className={`${styles.textInput} ${isInvalid ? styles.textInputInvalid : ''}`}
        type={type}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        style={style}
        defaultValue={defaultValue}
        name={name}
        tabIndex={-1}
        min={min}
        max={max}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        maxLength={maxLength}
        inputMode={inputMode}
      />
    </div>
  );
}

type PhoneInputProps = BasePhoneInputProps & {
  refvalue: BaseInputProps['ref'];
  isInvalid?: boolean;
  title?: React.ReactNode;
};

export function PhoneInput({ value, title, onChange, isInvalid, refvalue, onKeyDown, onFocus }: PhoneInputProps) {
  const classNames = classes(styles.textInput, isInvalid && styles.textInputInvalid);

  return (
    <div>
      <div className={styles.textInputLabel}>{title}</div>
      <PhoneInputComponent
        containerClass={styles.phoneInputContainer}
        preferredCountries={['us']}
        buttonClass={styles.phoneInputButton}
        dropdownStyle={{ textAlign: 'left' }}
        inputClass={classNames}
        value={value}
        placeholder=""
        onChange={onChange}
        inputProps={{ ref: refvalue }}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
      />
    </div>
  );
}
