import CashIcon from '@assets/images/cash.png';

import { buildBasicNotification } from '@components/layout/notification/variations/BasicNotification';
import VerifyEmailNotification from '@components/layout/notification/components/VerifyEmailNotification';

import { Notification, NotificationType } from '@models/application/notifications';
import { AccountClosurePayload } from '@models/application/notifications/closure';
import { VerifyEmailPayload } from '@models/application/notifications/email';
import { InfoNotificationPayload } from '@models/application/notifications/info';
import { AccountRejectedPayload } from '@models/application/notifications/rejected';

import { hideNotification } from '@utils/notifications';

const notificationMap = [
  {
    type: NotificationType.AccountClosure,
    component: (notification: Notification) => {
      const { content, config } = notification.payload as AccountClosurePayload;
      return buildBasicNotification(config, content, CashIcon, () => hideNotification(notification));
    },
  },
  {
    type: NotificationType.AccountRejected,
    component: (notification: Notification) => {
      const { content, config } = notification.payload as AccountRejectedPayload;
      return buildBasicNotification(config, content, CashIcon, () => hideNotification(notification));
    },
  },
  /* @deprecated remove */
  {
    type: NotificationType.VerifyEmail,
    component: (notification: Notification) => {
      const { config } = notification.payload as VerifyEmailPayload;
      return <VerifyEmailNotification closable={config.closable} onHideClick={() => hideNotification(notification)} />;
    },
  },
  {
    type: NotificationType.Info,
    component: (notification: Notification) => {
      const { content, config } = notification.payload as InfoNotificationPayload;
      return buildBasicNotification(config, content, null, () => hideNotification(notification));
    },
  },
];

export default notificationMap;
