import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { organizationSelector } from '@store/organization/selectors';
import { registerLinksHandler } from '~/services/linksHandler';

import * as S from './Disclaimer.styled';

type Props = {
  className?: string;
  hideFeesApplyLink?: boolean;
  onLinkClick?: VoidFunction;
};

export const Disclaimer: React.FC<Props> = ({ onLinkClick, ...props }) => {
  const organization = useSelector(organizationSelector);
  const fullTermsLink = useMemo(() => organization?.organizationInfo?.full_disclosure_link, [organization]);
  const [containerElement, setContainerElement] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (containerElement) {
      const unsubscribe = registerLinksHandler(containerElement);
      return () => {
        unsubscribe();
      };
    }
  }, [containerElement]);

  const updateContainerElement = useCallback((el: HTMLDivElement | null) => {
    if (el) {
      setContainerElement(el);
    }
  }, []);

  const text = useMemo(() => {
    if (!organization.organizationInfo) {
      return '';
    } else if (!organization.organizationInfo.disclaimer?.length) {
      return (
        'Unlike bank deposits, investments are not insured by the FDIC, ' +
        `are not deposits and are not guaranteed by ${organization.organizationInfo?.display_name}. ` +
        'Investments are subject to risks, including possible loss of principal.'
      );
    }
    return organization.organizationInfo.disclaimer;
  }, [organization.organizationInfo]);

  return (
    <S.Container className={props.className} ref={updateContainerElement}>
      {text ? (
        <>
          {text} {!props.hideFeesApplyLink && <S.FeesLink onClick={onLinkClick}>Fees may apply.</S.FeesLink>}{' '}
          {fullTermsLink && (
            <S.DisclosuresLink onClick={onLinkClick} href={fullTermsLink} target="_blank">
              Read full disclosure.
            </S.DisclosuresLink>
          )}
        </>
      ) : null}
    </S.Container>
  );
};
