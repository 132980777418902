import { useMemo, useEffect } from 'react';
import debounce from 'lodash/debounce';

export const useOnResize = (
  onResize: (width: number, height: number) => void,
  targetElement: HTMLElement | null = document.body,
  debounceTime = 300,
) => {
  const debouncedOnResize = useMemo(() => debounce(onResize, debounceTime), [onResize, debounceTime]);

  const observer = useMemo(
    () =>
      new ResizeObserver((entries) => {
        const values = entries[0];
        if (values) {
          debouncedOnResize(values.contentRect.width, values.contentRect.height);
        }
      }),
    [debouncedOnResize],
  );

  // Call onResize once when element was mounted
  useEffect(() => {
    if (targetElement) {
      debouncedOnResize(targetElement.offsetWidth, targetElement.offsetHeight);
    }
  }, [targetElement, debouncedOnResize]);

  // Call onResize once when element was mounted
  useEffect(() => {
    if (targetElement) {
      observer.observe(targetElement);
      return () => {
        observer.disconnect();
      };
    }
  }, [targetElement, observer]);
};
