import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as RD from '@devexperts/remote-data-ts';
import { pipe } from 'fp-ts/function';

import { CREATE_PORTFOLIO } from '@routes/names';
import { checkDeviceSize, useAppMediaContext } from '~/contexts/AppMedia';
import { activePortfolioSelector, PortfolioListPlain, useSetActivePortfolio } from '~/entities/protfolio';

import * as S from './portfolioSelector.feature.styled';
import { usePortfolioList } from '~/entities/protfolio/list';

type Props = {
  className?: string;
  leftPosition: number;
  onVisibilityChanged: VoidFunction;
};

export const PortfolioSelector: React.FC<Props> = (props) => {
  const activeId = useSelector(activePortfolioSelector);
  const { deviceSize } = useAppMediaContext();
  const isMobile = !checkDeviceSize.moreOrEqual.md(deviceSize);
  const portfolioList = usePortfolioList();
  const setActivePortfolio = useSetActivePortfolio();

  const portfolios = useMemo(
    () =>
      pipe(
        portfolioList,
        RD.getOrElse((): PortfolioListPlain => []),
      ),
    [portfolioList],
  );

  const mappedOptions = useMemo(
    () =>
      portfolios.map((item) => ({
        label: <S.Item {...item} isSelected={activeId === item.id} />,
        value: item.id,
        stringName: item.name,
      })),
    [portfolios, activeId],
  );

  const onSelect = useCallback(
    (val: { value: string } | string) => {
      const targetId = typeof val === 'string' ? val : val.value;

      setActivePortfolio(targetId);
    },
    [setActivePortfolio],
  );

  return (
    <>
      <S.DropdownStyles $isMobile={isMobile} $leftPosition={props.leftPosition} />
      <S.Container
        onDropdownVisibleChange={props.onVisibilityChanged}
        loading={RD.isPending(portfolioList)}
        options={mappedOptions}
        popupClassName={S.DROPDOWN_CLASSNAME}
        optionLabelProp="stringName"
        onSelect={onSelect}
        value={activeId}
        className={props.className}
        before={<S.Before>Switch portfolio</S.Before>}
        after={
          <S.After link={CREATE_PORTFOLIO}>
            <S.AddIcon />
            Add portfolio
          </S.After>
        }
      />
    </>
  );
};
