import * as company from '~/services/company';

import { OperationType } from '../../organization.types';

export const getMinimalAmount = (type: OperationType, settings: company.SettingsContextState) => {
  switch (type) {
    case OperationType.DEPOSIT:
      return company.getSettingsKey(settings, company.DEPOSIT_MINIMUM_KEY);
    case OperationType.WITHDRAW:
      return company.getSettingsKey(settings, company.WITHDRAWAL_MINIMUM_KEY);
    default:
      return 0;
  }
};
