import { useMemo } from 'react';

import { getMinimalAmount } from '../../utils';
import { OperationType } from '../../organization.types';
import { useOrganizationSettings } from '../useOrganizationSettings';

export const useMinimalDepositAmount = () => {
  const settings = useOrganizationSettings();

  return useMemo(() => getMinimalAmount(OperationType.DEPOSIT, settings), [settings]);
};
