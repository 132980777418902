import { Children, FC, isValidElement, PropsWithChildren, useMemo } from 'react';
import { RouteProps } from 'react-router';
import { Switch, Route, useLocation } from 'react-router-dom';
import SideBarLayout from '~/ui/layouts/SideBarLayout';
import FullScreenLayout from '~/ui/layouts/FullScreenLayout';

type Layout = 'sidebar' | 'fullscreen';

const EmptyWrapperComponent: FC<PropsWithChildren> = (props) => <>{props.children}</>;

export const SwitchRouteLayoutWrapper: FC<PropsWithChildren> = ({ children }) => {
  const { pathname } = useLocation();

  /* Check for active route and select a wrapper component based on active route's layout prop */
  const WrapperComponent = useMemo(() => {
    let layout: Layout | undefined;

    Children.forEach(children, (child) => {
      if (!isValidElement(child) || child.props.path !== pathname) {
        return;
      }
      layout = child.props.layout;
    });

    switch (layout) {
      case 'sidebar':
        return SideBarLayout;
      case 'fullscreen':
        return FullScreenLayout;
      default:
        return EmptyWrapperComponent;
    }
  }, [children, pathname]);

  return (
    <WrapperComponent>
      <Switch>{children}</Switch>
    </WrapperComponent>
  );
};

/* Disable render prop to have the only way to interact with "render" props for a route component
 * The only way to interact left - "component" prop */
export type RouteLayoutProps = Omit<RouteProps<string>, 'render'> & { layout?: Layout };

/* Wrapper needed only for handling layout's prop inside of SwitchRouteLayoutWrapper component */
export const RouteLayout: FC<RouteLayoutProps> = ({ layout: _, ...props }) => {
  return <Route {...props} />;
};

RouteLayout.defaultProps = {
  layout: 'sidebar',
  exact: true,
};
