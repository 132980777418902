import React, { FC } from 'react';
import loadable from '@loadable/component';

type Props = {
  className?: string;
};

const NewPortfolioPageContent = loadable(() => import('./NewPortfolio.page'), { fallback: <></> });

export const NewPortfolioPage: FC<Props> = () => {
  return <NewPortfolioPageContent />;
};
