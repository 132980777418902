import * as components from '../components';
import * as routes from '../names';
import { ProtectedRoute } from '../ProtectedRoute';
import { RouteLayout } from '../RouteLayout';
import { getPathAndKeyPropsFactory } from './helpers';

const getPathAndKeyProps = getPathAndKeyPropsFactory('account-closure');

const commonDisallowedPagesProps = {
  isAllowed: false,
  layout: 'fullscreen' as const,
  component: () => <></>,
  redirectPath: routes.NEW_ACCOUNT,
};

export const getClosedAccountRoutes = (hasAlpacaAccount: boolean) => [
  <ProtectedRoute {...getPathAndKeyProps(routes.MAIN)} {...commonDisallowedPagesProps} />,
  <ProtectedRoute {...getPathAndKeyProps(routes.PORTFOLIO)} {...commonDisallowedPagesProps} />,
  <ProtectedRoute {...getPathAndKeyProps(routes.FUND_WITHDRAW)} {...commonDisallowedPagesProps} />,
  <ProtectedRoute {...getPathAndKeyProps(routes.CREATE_PORTFOLIO)} {...commonDisallowedPagesProps} />,
  <ProtectedRoute {...getPathAndKeyProps(routes.KYC)} {...commonDisallowedPagesProps} />,
  <ProtectedRoute {...getPathAndKeyProps(routes.ONBOARDING_ALPACA)} {...commonDisallowedPagesProps} />,
  <ProtectedRoute {...getPathAndKeyProps(routes.GET_STARTED_EXTERNAL)} {...commonDisallowedPagesProps} />,

  <RouteLayout
    {...getPathAndKeyProps(routes.NEW_ACCOUNT)}
    component={() => <components.Account hasAlpacaAccount={hasAlpacaAccount} />}
  />,
  <RouteLayout {...getPathAndKeyProps(routes.FAQ)} component={components.FAQ} />,
  <RouteLayout layout="fullscreen" {...getPathAndKeyProps(routes.ERROR_PAGE)} component={components.ErrorPage} />,
  <RouteLayout layout="fullscreen" {...getPathAndKeyProps(routes.ANY)} component={components.PageNotFound} />,
];
