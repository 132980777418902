import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getCompanySettings } from '~/services/company';
import { selectOrganization } from '@store/organization/selectors';

export const useOrganizationSettings = () => {
  const organization = useSelector(selectOrganization);

  return useMemo(() => getCompanySettings(organization), [organization]);
};
